$(document).ready(function() {

	// 초기화
	if( $(window).width() > 960 ) {
		$('.wwc-img-item').attr('style', 'will-change: transform; transform: translate3d(0px, 0vh, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;');
		$('.wwc-img').attr('style', 'will-change: transform; transform: translate3d(0px, 75vh, 75vw) scale3d(1, 1, 1) rotateX(135deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;');
	} 


	$(window).on('scroll.wmc_section', function() {
		var wh = $(window).height();
		var wt = $(window).scrollTop() + wh;
		var wt2 = $(window).scrollTop() + wh / 2;
		// 현재 스크롤 위치에 - wwc-img
		// 스크롤높이가 이미지의 중앙에 오면 rotateX(0), translate3d(0,0,0) 이다
		// 그 이상 올라가면 작아져야 하므로, 부모의 y 와 자식의 z가 음수로 동일하게 움직임.
		if( $(window).width() > 960 ) {
			$('.wwc-img-item').each(function(a,b) {
				var p_top = $(this).offset().top;
				var p_height = $(this).height();
				if( wt > p_top ) {
					var child_y = 75;
					var child_z = 75;
					var child_rotate = 135;
					var cal_move = wt - p_top;
					var cal_move2 = wt2 - p_top - p_height /2 < 0 ? 0 : wt2 - p_top - p_height/2 ;
					var cal_y = 1 - ((cal_move / (p_height/0.75)) > 1 ? 1 : (cal_move / (p_height/0.75)));
					var cal_z = cal_move2 > 0 ? ((cal_move2 / p_height) > 1 ? 1 : (cal_move2 / p_height))*32 : 0;
					

					$(this).attr('style', 'will-change: transform; transform: translate3d(0px, '+(cal_z)+'vh, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;');
					$(this).find('.wwc-img').attr('style', 'will-change: transform; transform: translate3d(0px, '+(child_y * cal_y)+'vh, '+(cal_z > 0 ? cal_z*-1 : child_z * cal_y)+'vw) scale3d(1, 1, 1) rotateX('+(child_rotate * cal_y)+'deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;');
	
				}

	
			})
		} else {
			$('.wwc-img-item').removeAttr('style')
			$('.wwc-img').removeAttr('style')
		}
	});	

	$('.wwd-item-link').on('click',function(e) {
		e.preventDefault();
		if( $(this).parents('li').hasClass('open')== false) {
			$(this).parents('li').addClass('open');
			$(this).parents('li').siblings('li').removeClass('open');
			
		}
		
	})

	$(window).on('scroll.the75007_section', function() {
		var wh = $(window).height();
		var wt = $(window).scrollTop() + wh;
		var wt2 = $(window).scrollTop() + wh / 2;
		var half_wh = wh / 2;

		// obj 가 스크롤 안에 들어오면 시작
		// 화면의 중앙을 넘어서면 100%
		$('.fill-text .text-item').each(function(a,b) {
			var p_top = $(this).offset().top;
			var p_height = $(this).height();
			if( wt > p_top ) {
				var width_per = ( wt - p_top ) / half_wh * 100;
				width_per = width_per > 100 ? 100 : width_per;
				$(this).find('.text-hover-row').css('width', width_per+'%');
			}
		})
	});		 
	$('.wcde-item').on('mouseenter',function() {
		$(this).addClass('hover');
		$(this).siblings().removeClass('hover').addClass('no-hover');
		if( $(window).width() < 961 ) {
			$('.wcde-section .title').addClass('no-hover')
		}
	}).on('mouseleave',function() {
		$(this).removeClass('hover');
		$(this).siblings().removeClass('no-hover');
		if( $(window).width() < 961 ) {
			$('.wcde-section .title').removeClass('no-hover')
		}		
	});



	$('.btn_connect, section.getintouch .btn-close').on('click',function(e) {
		e.preventDefault();
		$('section.getintouch').toggleClass('open')
		return false;
	})

	
})	