


$(document).ready(function() {
	$(window).on('scroll.imagine_section', function() {
		var wh = $(window).height();
		var wt = $(window).scrollTop() + wh/1.5;
		$('.animation_item').each(function() {
			var t_height = $(this).outerHeight();
			var t_top = $(this).offset().top ;
			var t_position_top = $(this).position().top ; 
			if( $(window).width() > 960 ) var width = 1920;
			else var width = 360;
			
			if( wt > t_top + t_height) {
				// wt - t_top 은? 엘리멘트가 화면에 노출되는 순간부터 스크롤의 변화
				// ratio 가 0.1이라면 스크롤이 100px 움직이면, 엘리멘트는 10px 움직임.
				// 1920 기준으로 0.1 이므로 화면의 너비가 그것보다 작다면, 세로도 작아지므로 ratio 도 더 작아져야 한다.
				var cal_ratio = $(window).width() / width * parseFloat($(this).data('moveratio'));
				var cal_move = ( wt - t_top + t_height ) * cal_ratio;
				// bottom -100% -> 0 이므로 , cal_move 의 값에 대한 비율을 조절해야함. cal_move 가 t_height 보다 커지면 0으로 고정
				var cal_bottom = (1- ((cal_move / t_height) > 1 ? 1 : (cal_move / t_height)))*100 * -1;
				var cal_opacity = ((cal_move / t_height) > 1 ? 1 : (cal_move / t_height));
				$(this).find('.animation_move_item').css('bottom', cal_bottom+'%').css('opacity', cal_opacity);
			}
		})
	});

	$('.btn-toggle-gnb').on('click',function(e) {
		e.preventDefault();
		$('.hd_gnb').toggleClass('open');
	})
	$('.hd_lang-item').on('click',function(e) {
		e.preventDefault();
		$(this).parents('.hd_lang').find('.hd_lang-item').removeClass('on').end().end().addClass('on');
		if( $('.hd_lang-kr').hasClass('on') ) {
			$('body').addClass('sel_lang_en').removeClass('sel_lang_kr');
		} else {
			$('body').addClass('sel_lang_kr').removeClass('sel_lang_en');
		}
	})

	var flow_text_marquee = $('.footer-flow-text').marquee({
		allowCss3Support: false,
		delayBeforeStart: 0,
		direction: 'left',
		duplicated: true,
		duration: 38000,
		gap: 0,
		startVisible: true,
		hoverSlow: false
	});	
    
})

